import React, { useState, useEffect } from "react"
import { getQueryVariable } from "../lib/util"
import { navigate } from "gatsby"
import Layout from "../zzz/layout"
import Checkout from "../components/Checkout/Checkout"
import { isInStoreFrontend } from "../services/auth"

const OrderStatusPage = ({ location }) => {
  const [orderStatusInStoreFrontend, setOrderStatusInStoreFrontend] = useState(
    false
  )

  useEffect(() => {
    if (!orderStatusInStoreFrontend) {
      if (isInStoreFrontend()) {
        setOrderStatusInStoreFrontend(true)
      }
    }
  }, [])

  const queryOrderId = getQueryVariable("orderid")
  // Bad order ID given. Let's just go to customer My Account
  if (typeof window != "undefined" && !parseInt(queryOrderId)) {
    navigate("/my-account/?&o")
  }

  return (
    <Layout location={location}>
      <Checkout
        location={location}
        givenOrderId={queryOrderId}
        givenTabIdx={2}
        orderStatusInStoreFrontend={orderStatusInStoreFrontend}
      />
    </Layout>
  )
}

export default OrderStatusPage
